import React from 'react'

import iphonenitro from '../../assets/images/app/iPhone2.png';
import nitro1 from '../../assets/images/nitro1.png';
import nitro2 from '../../assets/images/nitro2.png';

import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';

import * as Icon from 'react-feather'
import {BsCheckCircle, MdKeyboardArrowRight,FaArrowRight} from '../../assets/icons/icons'
import upi_icon from '../../assets/images/upi.svg'
import payments_icon from '../../assets/images/payments.svg'
import banking_icon from '../../assets/images/banking.svg'
import payouts_icon from '../../assets/images/payouts.svg'
import { Link } from 'react-router-dom';
import CookieModal from '../../component/cookieModal';
import MobileApp from '../../component/mobileApp';

export default function IndexApps() {
    const featureData = [
        {
          icon:upi_icon,
          title:"UPI",
          desc:"Provide a seamless UPI payment experience"
        },
        {
          icon:payments_icon,
          title:"Bank Transfer",
          desc:"Provide bank transfer options to your customers"
        },
        {
          icon:banking_icon,
          title:"NET Banking",
          desc:"Accept online banking payments"
        },
        {
          icon:payouts_icon,
          title:"Payouts",
          desc:"Send payouts to your customers"
        }
    ]
    return (
        <>
            <Navbar navClass="nav-light" />

            <section className="relative table w-full py-36 md:py-48 md:pb-0 overflow-hidden bg-[url('../../assets/images/app/bg.png')] bg-top bg-no-repeat bg-fixed bg-cover">
                <div className="absolute inset-0 bg-indigo-600 opacity-80"></div>
                <div className="container relative z-2">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-8 md:col-span-7 md:mb-16">
                            <div className="md:me-6">
                                <h4 className="font-bold text-white lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5">Make Faster, <br /> More Confident <br /> Payments</h4>
                                <p className="text-white/70 text-lg max-w-xl"> We facilitate secure and swift transactions, enabling companies to focus on their core operations without the hassles of traditional payment processes.</p>

                                <div className="mt-6">
                                  <Link to="#" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center text-white rounded-md me-2">Get Started</Link>
                                  <Link to="#" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center text-white rounded-md me-2">See the Demo</Link>
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-span-4 md:col-span-5 md:mt-0">
                            <div className="relative after:content-[''] after:absolute after:h-40 after:w-40 after:bg-indigo-600 after:bottom-0 after:end-0  after:-z-0 after:rounded-3xl after:animate-[spin_10s_linear_infinite]">
                                <img src={iphonenitro} className="relative z-1" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className="relative">
                <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>



            <section className="relative md:py-24 py-16 overflow-hidden">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h6 className="text-indigo-600 text-[26px] font-bold uppercase mb-2">Features</h6>
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">What We Do ?</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">We are a cutting-edge payment provider at the forefront of financial technology, empowering businesses to seamlessly transfer money through our sophisticated automated tools.</p>
                    </div>

                    <div className="grid md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                        {featureData.map((item,index)=>{
                            let Icons = item.icon
                            return(
                                <div key={index} className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
                                    <div
                                        className="w-16 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center dark:shadow-gray-800 mx-auto">
                                        <img src={Icons} className="l-dark" width="150" alt="" />
                                    </div>
        
                                    <div className="content mt-7">
                                        <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">{item.title}</Link>
                                        <p className="text-slate-400 mt-3">{item.desc}</p>
        
                                        <div className="mt-5">
                                            <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>

                <div className="container relative md:mt-24 mt-16">
                    <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
                        <div className="relative">
                            <img src={nitro1} className="mx-auto w-[300px]" alt="" />
                            <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-2/4 translate-y-2/4 start-0 rotate-45 -z-1 shadow-md shadow-indigo-600/10 rounded-[100px]"></div>
                        </div>

                        <div className="lg:ms-8">
                            <h4 className="mb-4 md:text-3xl text-2xl lg:leading-normal leading-normal font-medium">Unleashing Seamless Transactions with UPIs</h4>
                            <p className="text-slate-400"> Our innovative platform ensures effortless fund transfers, bill payments, and a range of financial transactions. Enjoy a unified and efficient payment experience that's both user-friendly and real-time, simplifying the world of digital finance.</p>
                            <ul className="list-none text-slate-400 mt-4">
                                <li className="mb-1 flex items-center"><BsCheckCircle className="text-indigo-600 text-base me-2"/> Enable universal transactions across banks and financial institutions.</li>
                                <li className="mb-1 flex items-center"><BsCheckCircle className="text-indigo-600 text-base me-2"/> Experience instant fund transfers for enhanced transaction efficiency.</li>
                                <li className="mb-1 flex items-center"><BsCheckCircle className="text-indigo-600 text-base me-2"/> Enjoy hassle-free digital payments with UPIS' intuitive design.</li>
                            </ul>

                            <div className="mt-4">
                                <Link to="/" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl ms-1"/></Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container relative md:mt-24 mt-16 md:mb-20">
                    <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                        <div className="relative order-1 md:order-2">
                            <img src={nitro2} className="mx-auto mx-auto w-[300px]" alt="" />
                            <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-2/4 translate-y-2/4 end-0 rotate-45 -z-1 shadow-md shadow-indigo-600/10 rounded-[100px]"></div>
                        </div>

                        <div className="lg:me-8 order-2 md:order-1">
                            <h4 className="mb-4 md:text-3xl text-2xl lg:leading-normal leading-normal font-medium">Elevate Transactions with API & Webhooks Integrations</h4>
                            <p className="text-slate-400"> Explore the possibilities of advanced integration with APIs, where convenience and innovation converge for a superior payment experience.</p>
                            <ul className="list-none text-slate-400 mt-4">
                                <li className="mb-1 flex items-center"><BsCheckCircle className="text-indigo-600 text-base me-2"/> Effortlessly incorporate APIS into your process for streamlined operations.</li>
                                <li className="mb-1 flex items-center"><BsCheckCircle className="text-indigo-600 text-base me-2"/>  Unlock additional features with APIS for enhanced transaction capabilities.</li>
                                <li className="mb-1 flex items-center"><BsCheckCircle className="text-indigo-600 text-base me-2"/> Leverage APIS to optimize digital payment performance on UPIS for reliability.</li>
                            </ul>

                            <div className="mt-4">
                                <Link to="/" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl ms-1"/></Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="container relative md:mt-24 mt-16">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Key Features</h3>
                        <p className="text-slate-400 max-w-xl mx-auto">Create, collaborate, and turn your ideas into incredible products with the definitive platform for digital design.</p>
                    </div>

                    <div className="grid lg:grid-cols-12 md:grid-cols-12 grid-cols-1 mt-8 gap-[30px] items-center">
                        <div className="lg:col-span-4 md:col-span-6 lg:order-1 order-2">
                            <div className="grid grid-cols-1 gap-[30px]">
                                <div className="group flex transition-all duration-500 ease-in-out xl:p-3">
                                    <div className="flex md:order-2 order-1 align-middle justify-center items-center w-14 h-14 mt-1 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white text-indigo-600 rounded-full text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                        <Icon.Monitor className="w-5 h-5"></Icon.Monitor>
                                    </div>
                                    <div className="flex-1 md:order-1 order-2 md:text-end md:me-4 md:ms-0 ms-4">
                                        <h4 className="mb-0 text-lg font-medium">Use On Any Device</h4>
                                        <p className="text-slate-400 mt-3">One disadvantage of Lorum Ipsum is that in Latin frequently than others</p>
                                    </div>
                                </div>


                                <div className="group flex transition-all duration-500 ease-in-out xl:p-3">
                                    <div className="flex md:order-2 order-1 align-middle justify-center items-center w-14 h-14 mt-1 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white text-indigo-600 rounded-full text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                        <Icon.Feather className="w-5 h-5"></Icon.Feather>
                                    </div>
                                    <div className="flex-1 md:order-1 order-2 md:text-end md:me-4 md:ms-0 ms-4">
                                        <h4 className="mb-0 text-lg font-medium">Feather Icons</h4>
                                        <p className="text-slate-400 mt-3">One disadvantage of Lorum Ipsum is that in Latin frequently than others</p>
                                    </div>
                                </div>


                                <div className="group flex transition-all duration-500 ease-in-out xl:p-3">
                                    <div className="flex md:order-2 order-1 align-middle justify-center items-center w-14 h-14 mt-1 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white text-indigo-600 rounded-full text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                        <Icon.Eye className="w-5 h-5"></Icon.Eye>
                                    </div>
                                    <div className="flex-1 md:order-1 order-2 md:text-end md:me-4 md:ms-0 ms-4">
                                        <h4 className="mb-0 text-lg font-medium">Retina Ready</h4>
                                        <p className="text-slate-400 mt-3">One disadvantage of Lorum Ipsum is that in Latin frequently than others</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="lg:col-span-4 md:col-span-12 lg:mx-8 lg:order-2 order-1">
                            <img src={feature} className="mx-auto md:max-w-[300px]" alt="" />
                        </div>

                        <div className="lg:col-span-4 md:col-span-6 order-3">
                            <div className="grid grid-cols-1 gap-[30px]">
                                <div className="group flex transition-all duration-500 ease-in-out xl:p-3">
                                    <div className="flex align-middle justify-center items-center w-14 h-14 mt-1 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white text-indigo-600 rounded-full text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                        <Icon.UserCheck className="w-5 h-5"></Icon.UserCheck>
                                    </div>
                                    <div className="flex-1 ms-4">
                                        <h4 className="mb-0 text-lg font-medium">W3c Valid Code</h4>
                                        <p className="text-slate-400 mt-3">One disadvantage of Lorum Ipsum is that in Latin frequently than others</p>
                                    </div>
                                </div>


                                <div className="group flex transition-all duration-500 ease-in-out xl:p-3">
                                    <div className="flex align-middle justify-center items-center w-14 h-14 mt-1 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white text-indigo-600 rounded-full text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                        <Icon.Smartphone className="w-5 h-5"></Icon.Smartphone>
                                    </div>
                                    <div className="flex-1 ms-4">
                                        <h4 className="mb-0 text-lg font-medium">Fully Responsive</h4>
                                        <p className="text-slate-400 mt-3">One disadvantage of Lorum Ipsum is that in Latin frequently than others</p>
                                    </div>
                                </div>


                                <div className="group flex transition-all duration-500 ease-in-out xl:p-3">
                                    <div className="flex align-middle justify-center items-center w-14 h-14 mt-1 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white text-indigo-600 rounded-full text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                        <Icon.Heart className="w-5 h-5"></Icon.Heart>
                                    </div>
                                    <div className="flex-1 ms-4">
                                        <h4 className="mb-0 text-lg font-medium">Browser Compatibility</h4>
                                        <p className="text-slate-400 mt-3">One disadvantage of Lorum Ipsum is that in Latin frequently than others</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <MobileApp/> */}
            </section>


            <div className="relative">
                <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-dark-footer">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>


            <Footer />
            <CookieModal />
        </>
    )
}
