import React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './assets/libs/@mdi/font/css/materialdesignicons.min.css';
import Index from './pages/index';
import Navbar from './component/Navbar/navbar';
import PageTerms from './pages/utility/pageTerms';
import PagePrivacy from './pages/utility/pagePrivacy';
import PageMaintenance from './pages/special/pageMaintenance';
import PageError from './pages/special/pageError';
import Helpcenter from './pages/helpcenter/helpcenter';
import HelpcenterSupport from './pages/helpcenter/helpcenterSupport';
import HelpcenterFaqs from './pages/helpcenter/helpcenterFaqs';
import Documentation from './pages/documentation';
import ContactOne from './pages/contact/contactOne';
import Changelog from './pages/changelog';
import HelpcenterGuides from './pages/helpcenter/helpcenterGuides';

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Index />} />
                <Route exact path="/navbar" element={<Navbar />} />
                <Route exact path="/page-terms" element={<PageTerms />} />
                <Route exact path="/page-privacy" element={<PagePrivacy />} />
                <Route exact path="/page-maintenance" element={<PageMaintenance />} />
                <Route exact path="/page-error" element={<PageError />} />
                <Route exact path="/helpcenter-overview" element={<Helpcenter />} />
                <Route exact path="/helpcenter-support" element={<HelpcenterSupport />} />
                <Route exact path="/helpcenter-faqs" element={<HelpcenterFaqs />} />
                <Route exact path="/helpcenter-guides" element={<HelpcenterGuides />} />
                <Route exact path="/documentation" element={<Documentation />} />
                <Route exact path="/contact-one" element={<ContactOne />} />
                <Route exact path="/changelog" element={<Changelog />} />
            </Routes>
        
        </BrowserRouter>
    )
}
