import React from 'react'
import logo_dark from '../../assets/images/nitro-black.svg';
import logo_light from '../../assets/images/nitro-white.svg';
import '../../assets/libs/@mdi/font/css/materialdesignicons.min.css';
import '../../assets/css/tailwind.css';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Switch from '../Switch';

export default function Navbar(props) {
    const { navClass, navJustify } = props;

    window.addEventListener("scroll", windowScroll);

    const [isMenu, setisMenu] = useState(false);

    const toggleMenu = () => {
        setisMenu(!isMenu);
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href")
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                })
            });
        }
    };
    
    useEffect(() => {
        activateMenu();
        window.scrollTo(0, 0)
    }, []);

    function windowScroll() {
        const navbar = document.getElementById("topnav");
        if (
            document.body.scrollTop >= 50 ||
            document.documentElement.scrollTop >= 50
        ) {
            if (navbar !== null) {
                navbar?.classList.add("nav-sticky");
            }
        } else {
            if (navbar !== null) {
                navbar?.classList.remove("nav-sticky");
            }
        }

        const mybutton = document.getElementById("back-to-top");
        if (mybutton != null) {
            if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
                mybutton.classList.add("flex");
                mybutton.classList.remove("hidden");
            } else {
                mybutton.classList.add("hidden");
                mybutton.classList.remove("flex");
            }
        }
    }

    const activateMenu = () => {
        var menuItems = document.getElementsByClassName("sub-menu-item");
        if (menuItems) {

            var matchingMenuItem = null;
            for (var idx = 0; idx < menuItems.length; idx++) {
                if (menuItems[idx].href === window.location.href) {
                    matchingMenuItem = menuItems[idx];
                }
            }
            if (matchingMenuItem) {
                matchingMenuItem.classList.add('active');

                var immediateParent = getClosest(matchingMenuItem, 'li');

                if (immediateParent) {
                    immediateParent.classList.add('active');
                }

                var parent = getClosest(immediateParent, '.child-menu-item');
                if (parent) {
                    parent.classList.add('active');
                }

                var parent = getClosest(parent || immediateParent, '.parent-menu-item');

                if (parent) {
                    parent.classList.add('active');

                    var parentMenuitem = parent.querySelector('.menu-item');
                    if (parentMenuitem) {
                        parentMenuitem.classList.add('active');
                    }

                    var parentOfParent = getClosest(parent, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                } else {
                    var parentOfParent = getClosest(matchingMenuItem, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                }
            }
        }
    }

    const getClosest = (elem, selector) => {

        // Element.matches() polyfill
        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function (s) {
                    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i = matches.length;
                    while (--i >= 0 && matches.item(i) !== this) { }
                    return i > -1;
                };
        }

        // Get the closest matching element
        for (; elem && elem !== document; elem = elem.parentNode) {
            if (elem.matches(selector)) return elem;
        }
        return null;

    };

    var navbarHtml = <>
        <nav id="topnav" className={`defaultscroll ${navClass === "nav-light" ? '' : navClass === "nav-sticky" ?
            'bg-white dark:bg-slate-900' : ''}`}>
            <div className="container relative">
                {
                    navClass === "nav-light" ?
                        <Link className="logo" to="/">
                            <span className="inline-block dark:hidden">
                                <img src={logo_dark} className="l-dark" width="150" alt="" />
                                <img src={logo_light} className="l-light" width="150" alt="" />
                            </span>
                            <img src={logo_light} width="150" className="hidden dark:inline-block" alt="" />
                        </Link>
                        :
                        <Link className="logo" to="/">
                            <img src={logo_dark} width="150" className="inline-block dark:hidden" alt="" />
                            <img src={logo_light} width="150" className="hidden dark:inline-block" alt="" />
                        </Link>
                }

                <div className="menu-extras">
                    <div className="menu-item">
                        <Link to="#" className={`navbar-toggle ${isMenu ? 'open' : ''}`} id="isToggle" onClick={() => toggleMenu()}>
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Link>
                    </div>
                </div>
               
                <div id="navigation" style={{ display: isMenu ? 'block' : 'none' }}>
                    <ul className={`navigation-menu ${navClass} ${navJustify}`}>
                        <li><Link to="/" className="sub-menu-item">Home</Link></li>

                        <li className="has-submenu parent-parent-menu-item">
                            <Link to="#!">Help Center</Link><span className="menu-arrow"></span>
                            <ul className="submenu">
                                        <li><Link to="/helpcenter-overview" className="sub-menu-item">Overview</Link></li>
                                        <li><Link to="/helpcenter-faqs" className="sub-menu-item">FAQs</Link></li>
                                        <li><Link to="/helpcenter-guides" className="sub-menu-item">Guides</Link></li>
                                        <li><Link to="/helpcenter-support" className="sub-menu-item">Support</Link></li>
                            </ul>
                        </li>

                        <li className="has-submenu parent-menu-item">
                            <Link to="#">Docs</Link><span className="menu-arrow"></span>
                            <ul className="submenu">
                                <li><Link to="/documentation" className="sub-menu-item">API Reference</Link></li>
                                {/* <li><Link to="/changelog" className="sub-menu-item">Changelog</Link></li> */}
                            </ul>
                        </li>

                        {/* <li><Link to="/contact-one" className="sub-menu-item">Contact</Link></li> */}
                        {/* <li><Switch/></li> */}
                    </ul>

                </div>
            </div >

        </nav >
    </>
    return (
        <div >{navbarHtml}</div>
    )
}
